import PropTypes from "prop-types";

// material-ui
import {
  Box,
  Button,
  CardContent,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Collapse,
  IconButton
} from "@mui/material";

// project imports

import Avatar from "ui-component/extended/Avatar";
import SubCard from "ui-component/cards/SubCard";

// assets
import { IconEdit } from "@tabler/icons";
import PhonelinkRingTwoToneIcon from "@mui/icons-material/PhonelinkRingTwoTone";
import PinDropTwoToneIcon from "@mui/icons-material/PinDropTwoTone";
import MailTwoToneIcon from "@mui/icons-material/MailTwoTone";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

import Avatar3 from "assets/images/users/avatar-3.png";
import GamesPlayed from "ui-component/graphs/GamesPlayed";
import MainCard from "ui-component/cards/MainCard";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import PieChart from "ui-component/graphs/PieChart";
import { TopsixCategoriespieChartData } from "utils/constants";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// progress

const NestedCategory = ({ categoryData, level = 0, color, width }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleToggle = () => {
    setOpen(!open);
  };

  const getColor = (color) => {
    switch (color) {
      case "success":
        return {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.dark,
        };
      case "warning":
        return {
          backgroundColor: theme.palette.orange.light,
          color: theme.palette.orange.dark,
        };
      default:
        return {};
    }
  };

  return (
    <>
      <Grid 
        item 
        xs={12}
        sx={{ 
          pl: `${level * 60}px`, // Indent based on level
          bgcolor: level % 2 === 0 ? 'background.paper' : 'background.default', // Alternate background color
          py: 1,
          cursor: 'pointer', // Change cursor to pointer to indicate clickability
          backgroundColor: color,
           // Ensure full width for both categories and subcategories
        }}
        onClick={handleToggle} // Set onClick to toggle dropdown on the entire row
      >
        <Grid container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
          <Grid item>
            <Typography variant="subtitle1" color="inherit">
              {level === 0 && <span style={{ fontSize: 40, verticalAlign: 'middle',lineHeight:0 }}>
                  •
                </span>} {/* Add bullet point if it's the parent category */}
              {categoryData.category} {`(${categoryData.count})`}
            </Typography>
          </Grid>
          {categoryData.subcategories && categoryData.subcategories.length > 0 && (
            <Grid item>
              <IconButton 
                size="small" 
                onClick={handleToggle} 
                sx={{ 
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate the icon based on open state
                  transition: 'transform 0.3s ease' // Smooth transition for rotation
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>

        {/* Header Row */}
        {/* <Grid container alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Games Played
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Avg. Session
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Avg. Length
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Friends
            </Typography>
          </Grid>
            </Grid> */}

        {/* Data Row */}
        {/* <Grid container alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
          <Grid item>
            <Typography variant="subtitle1" color="inherit">
              {categoryData.NumberofGamesPlayed}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="inherit">
              {categoryData.AverageSessionLength}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="inherit">
              {categoryData.AverageSessionLength}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="inherit">
              {categoryData.NumberofFriends}
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>
      
      {categoryData.subcategories && (
        <Collapse style={{ width: "100%" }} in={open} timeout="auto" unmountOnExit>
          {categoryData.subcategories.map((subCategory, index) => (
            <React.Fragment key={index}>
              <NestedCategory width="100%" categoryData={subCategory} level={level + 1} />
                <Divider sx={{ my: 1.5, ml: `${(level + 1) * 20}px` }} />
              
            </React.Fragment>
            
          ))}
        </Collapse>
      )}
    </>
  );
};



const TopGamesDropdown = ({ skillsData }) => {
  return (
    <>
      {skillsData.map((skill, index) => (
        <React.Fragment key={index}>
          <NestedCategory categoryData={skill} />
          {index < skillsData.length - 1 && <Divider sx={{ my: 1.5 }} />}
        </React.Fragment>
      ))}
    </>
  );
};
export default TopGamesDropdown

