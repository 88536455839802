import { IconBrandChrome, IconHelp } from '@tabler/icons';
import NotificationsIcon from '@mui/icons-material/Notifications';
const icons = { IconBrandChrome, IconHelp };

const notificationlisting = {
    id: 'sample-docs-roadmap-3',
    type: 'group',
    children: [
        {
            id: 'notificationlisting',
            title: 'Notification Management',
            type: 'item',
            url: '/notification',
            icon: NotificationsIcon,
            breadcrumbs: false
        }
    ]
};

export default notificationlisting;
