import PropTypes from 'prop-types';

// material-ui
import {
    Box,
    Button,
    CardContent,
    Chip,
    Divider,
    Grid,
    LinearProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Collapse,
    IconButton
} from '@mui/material';
import { skillsDataGames } from 'utils/constants';
// project imports

import Avatar from 'ui-component/extended/Avatar';
import SubCard from 'ui-component/cards/SubCard';

// assets
import { IconEdit } from '@tabler/icons';
import PhonelinkRingTwoToneIcon from '@mui/icons-material/PhonelinkRingTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import Avatar3 from 'assets/images/users/avatar-3.png';
import GamesPlayed from 'ui-component/graphs/GamesPlayed';
import MainCard from 'ui-component/cards/MainCard';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import PieChart from 'ui-component/graphs/PieChart';
import { TopsixCategoriespieChartData } from 'utils/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TopGamesDropdown from 'ui-component/Dropdowns/TopGamesDropdown';
import BreadcrumbChart from 'ui-component/graphs/BreadcrumbChart';
import { AxiosInstance } from 'services/api/AxiosInstance';
// progress
const NestedCategory = ({ categoryData, level = 0, color, width, index }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    pl: `${level * 10}px`, // Indent based on level
                    bgcolor: level % 2 === 0 ? 'background.paper' : 'background.default', // Alternate background color
                    py: 1,
                    cursor: 'pointer', // Change cursor to pointer to indicate clickability
                    backgroundColor: color
                    // Ensure full width for both categories and subcategories
                }}
                onClick={handleToggle} // Set onClick to toggle dropdown on the entire row
            >
                <Grid container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                    <Grid container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                        <Grid sx={{ width: '100%' }} item>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" color="inherit">
                                    {level === 0 && <span style={{ fontSize: 40, verticalAlign: 'middle', lineHeight: 0 }}>•</span>}{' '}
                                    {/* Add bullet point if it's the parent category */}
                                    {level === 0 && categoryData.skillName}
                                </Typography>
                                <Typography variant="subtitle1" color="inherit" sx={{ fontWeight: 'bold' }}>
                                    {index + 1}
                                </Typography>
                            </div>
                        </Grid>

                        {level === 1 && (
                            <Grid item>
                                <Typography variant="subtitle1" color="inherit">
                                    {`${categoryData.game.gameName}`}
                                </Typography>
                            </Grid>
                        )}

                        {/* Data Row */}

                        {categoryData.subcategories && categoryData.subcategories.length > 0 && (
                            <Grid item>
                                <IconButton
                                    size="small"
                                    onClick={handleToggle}
                                    sx={{
                                        transform: open ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate the icon based on open state
                                        transition: 'transform 0.3s ease' // Smooth transition for rotation
                                    }}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {categoryData.subcategories && (
                <Collapse style={{ width: '100%' }} in={open} timeout="auto" unmountOnExit>
                    {categoryData.subcategories.map((subCategory, index) => (
                        <React.Fragment key={index}>
                            <NestedCategory width="100%" categoryData={subCategory} level={level + 1} />

                            <Divider sx={{ my: 1.5, ml: `${(level + 1) * 20}px` }} />
                        </React.Fragment>
                    ))}
                </Collapse>
            )}
        </>
    );
};

const SkillsDropdown = ({ skillsData }) => {
    return (
        <>
            {skillsData.map((skill, index) => (
                <React.Fragment key={index}>
                    <NestedCategory categoryData={skill} index={index} />
                    {index < skillsData.length - 1 && <Divider sx={{ my: 1.5 }} />}
                </React.Fragment>
            ))}
        </>
    );
};

function LinearProgressWithLabel({ value, ...others }) {
    // Example data, replace with your actual data

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    mr: 1
                }}
            >
                <LinearProgress value={value} {...others} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

// personal details table
/** names Don&apos;t look right */
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

// ==============================|| PROFILE 1 - PROFILE ||============================== //

const Games = () => {
    const skillsData = [
        {
            category: 'Pencil Sketching',
            count: 10,
            NumberofGamesPlayed: 12,
            NumberofSessions: 8,
            AverageSessionLength: 40,
            NumberofFriends: 10,
            color: 'primary',
            subcategories: [
                {
                    category: 'Visual Arts',
                    count: 5,
                    NumberofGamesPlayed: 12,
                    NumberofSessions: 8,
                    AverageSessionLength: 40,
                    NumberofFriends: 10,
                    level_3_category: 'Traditional Drawing',
                    level_2_category: 'Visual Arts',
                    level_1_category: 'ARTS & ACTS'
                }
            ]
        },
        {
            category: 'War and Conflict',
            count: 10,
            NumberofGamesPlayed: 12,
            NumberofSessions: 8,
            AverageSessionLength: 40,
            NumberofFriends: 10,
            color: 'primary',
            subcategories: [
                {
                    category: 'World History',
                    count: 5,
                    NumberofGamesPlayed: 12,
                    NumberofSessions: 8,
                    AverageSessionLength: 40,
                    NumberofFriends: 10,
                    level_3_category: 'Historical Events',
                    level_2_category: 'World History',
                    level_1_category: 'CULTURE & SOCIETY'
                }
            ]
        },
        {
            category: 'Software Tools',
            count: 10,
            NumberofGamesPlayed: 12,
            NumberofSessions: 8,
            AverageSessionLength: 40,
            NumberofFriends: 10,
            color: 'primary',
            subcategories: [
                {
                    category: 'Digital Media Production',
                    count: 5,
                    NumberofGamesPlayed: 12,
                    NumberofSessions: 8,
                    AverageSessionLength: 40,
                    NumberofFriends: 10,
                    level_3_category: 'Video Editing',
                    level_2_category: 'Digital Media Production',
                    level_1_category: 'MEDIA & MUSIC'
                }
            ]
        },
        {
            category: 'Newtonian Mechanics',
            count: 10,
            NumberofGamesPlayed: 12,
            NumberofSessions: 8,
            AverageSessionLength: 40,
            NumberofFriends: 10,
            color: 'primary',
            subcategories: [
                {
                    category: 'Subcategory 1.1',
                    count: 5,
                    level_3_category: 'Classical Mechanics',
                    level_2_category: 'Physics',
                    level_1_category: 'SCIENCE & TECHNOLOGY'
                }
            ]
        },
        {
            category: 'Passing Drills',
            count: 10,
            NumberofGamesPlayed: 12,
            NumberofSessions: 8,
            AverageSessionLength: 40,
            NumberofFriends: 10,
            color: 'primary',
            subcategories: [
                {
                    category: 'Subcategory 1.1',
                    count: 5,
                    level_3_category: 'Soccer',
                    level_2_category: 'Team Sports',
                    level_1_category: 'SPORTS & RECREATION'
                }
            ]
        }

        // other categories...
    ];

    const [anchorEl, setAnchorEl] = useState(null);
    const [topgames, settopgames] = useState([]);
    useEffect(() => {
        const gettopfivegames = async () => {
            try {
                const response = await AxiosInstance.get('/games/top-games');
                console.log('topgames', response);
                if (response.status === 200) {
                    const topfivegames = response.data.data.slice(0, 5);
                    settopgames(topfivegames);
                }
            } catch (err) {
                console.error(err);
            }
        };

        gettopfivegames();
    }, []);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const user = {
        name: 'Mr. Deepen Handgun'
    };
    const chartData = {
        height: 228,
        type: 'donut',
        options: {
            chart: {
                id: 'revenue-chart'
            },
            dataLabels: {
                enabled: false
            },
            labels: ['Youtube', 'Facebook', 'Twitter'],
            legend: {
                show: true,
                position: 'bottom',
                fontFamily: 'inherit',
                labels: {
                    colors: 'inherit'
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 10
                }
            }
        },
        series: [1258, 975, 500]
    };

    const rows = [
        createData('Full Name', ':', user?.name),
        createData('Fathers Name', ':', 'Mr. Deepen Handgun'),
        createData('Address', ':', 'Street 110-B Kalians Bag, Dewan, M.P. INDIA'),
        createData('Zip Code', ':', '12345'),
        createData('Phone', ':', '+0 123456789 , +0 123456789'),
        createData('Email', ':', 'support@example.com'),
        createData('Website', ':', 'http://example.com')
    ];

    const chartSeries = [44, 55, 13, 43, 22, 30];
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <MainCard content={false}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container alignContent="center" justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="h4">Top categories</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Menu
                                                    id="menu-skills-card"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                >
                                                    {/* Example of menu items, commented out if not needed */}
                                                    {/* <MenuItem onClick={handleMenuClose}>Today</MenuItem>
                    <MenuItem onClick={handleMenuClose}>This Month</MenuItem>
                    <MenuItem onClick={handleMenuClose}>This Year</MenuItem> */}
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid width={'100%'}>
                                        {/* <PieChart chartData={TopsixCategoriespieChartData} /> */}
                                        <BreadcrumbChart initialData={skillsDataGames} initialLabel="" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </MainCard>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <MainCard content={false}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container alignContent="center" justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="h4">Top 5 Games</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Menu
                                                    id="menu-skills-card"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                ></Menu>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <SkillsDropdown skillsData={topgames} />
                                </Grid>
                            </CardContent>
                        </MainCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Games;
