import React from 'react';
import { IconBrandChrome, IconHelp, IconUser } from '@tabler/icons';
// import SettingsIcon from '@mui/icons-material/Settings';
import Person2Icon from '@mui/icons-material/Person2';
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

const setting = {
    id: 'setting',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Profile',
            type: 'item',
            url: '/settings',
            icon: Person2Icon,
            breadcrumbs: false
        }
    ]
};

export default setting;
