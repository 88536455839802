import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const LineGraph = ({ data, title, initialTimeRange }) => {
    // console.log('data', data);
    // console.log(initialTimeRange);

    const options = {
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: title || 'Views and Downloads Over Time',
                font: {
                    size: 16,
                    weight: 'bold'
                },
                padding: {
                    top: 5,
                    bottom: 10
                }
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                offset: 0,
                font: {
                    weight: 'bold',
                    size: 12
                },
                color: '#000',
                formatter: function (value) {
                    return value;
                }
            }
        }
    };

    const chartData = {
        labels:
            initialTimeRange == 'weekly'
                ? data?.map((item) => item.date)
                : initialTimeRange == 'monthly'
                ? data?.map((item) => item.month)
                : data?.map((item) => item.year),
        datasets: [
            {
                label: 'Users',
                data: data?.map((item) => item.Usercount),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'Game Users',
                data: data?.map((item) => item.GamePlayers.IOS + item.GamePlayers.Android),
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                fill: true,
                tension: 0.4
            },
            // {
            //     label: 'IOS Users',
            //     data: data?.map((item) => item.IOS),
            //     borderColor: 'rgba(255, 99, 132, 1)',
            //     backgroundColor: 'rgba(255, 99, 132, 0.2)',
            //     fill: true,
            //     tension: 0.4
            // },
            // {
            //     label: 'Android Users',
            //     data: data?.map((item) => item.Android),
            //     borderColor: 'rgba(54, 162, 235, 1)',
            //     backgroundColor: 'rgba(54, 162, 235, 0.2)',
            //     fill: true,
            //     tension: 0.4
            // },
            {
                label: 'IOS Game Users',
                data: data?.map((item) => item.GamePlayers.IOS),
                borderColor: 'rgba(255, 206, 86, 1)',
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'Android Game Users',
                data: data?.map((item) => item.GamePlayers.Android),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4
            }
        ]
    };

    return (
        <>
            <div style={{ width: '100%', height: '400px', marginTop: '20px' }}>
                <Line data={chartData} options={options} />
            </div>
        </>
    );
};

export default LineGraph;
