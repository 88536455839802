import { useEffect, useState, useContext } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import EarningCard from './EarningCard';
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentTwoTone';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import SupervisorAccountTwoToneIcon from '@mui/icons-material/SupervisorAccountTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import GroupRemoveTwoToneIcon from '@mui/icons-material/GroupRemoveTwoTone';
import SportsEsportsTwoToneIcon from '@mui/icons-material/SportsEsportsTwoTone';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import { gridSpacing } from 'store/constants/constant';
import BarChart from 'ui-component/graphs/BarCharts';
import CustomBarChart from './CustomBarChart';
import { Registration, statusOptions } from 'utils/constants';
import ReportCard from 'ui-component/graphs/ReportCard';
import LineGraph from 'ui-component/graphs/LineGraph';
import { Line } from 'react-chartjs-2';
import LineChart from 'ui-component/graphs/dashboardLineGraph';
import PieChart from 'ui-component/graphs/PieChart';
import { Installation, userGrowth } from 'utils/constants';
import { DateContext } from 'context/DataSelectorContext';
import { getallcounts } from 'services/api/Dashboard';
import { AxiosInstance } from 'services/api/AxiosInstance';
import {
    dailyActiveUsers,
    monthlyActiveUsers,
    monthlyViewsData,
    pieChartData,
    weeklyActiveUsers,
    yearlyViewsData,
    weeklyViewsData
} from 'utils/constants';
import { getAnalyticsData } from 'services/api/Analyrics';
import { color } from '@mui/system';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    const { selectedDates, setSelectedDates } = useContext(DateContext);
    const [isLoading, setLoading] = useState(true);
    const [totalViews, setTotalViews] = useState(0);
    const [topActivities, setTopActivities] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);
    const [allCounts, setAllCounts] = useState(null);

    // console.log('allCounts', allCounts);
    // console.log('usersanalyticsgraph', usersanalyticsgraph);

    const [dailydata, setdailydata] = useState([]);
    const [registercount, setregistercount] = useState([]);
    // const [totalnoofusers, settotalnoofusers] = useState([]);
    // console.log('newusers', newusers);
    // console.log('totalnoofusers', totalnoofusers);
    const installVsInstall = [
        { name: 'Jan', Installs: 135, UnInstalls: 35 },
        { name: 'Feb', Installs: 125, UnInstalls: 15 },
        { name: 'Mar', Installs: 135, UnInstalls: 15 },
        { name: 'Apr', Installs: 25, UnInstalls: 35 },
        { name: 'May', Installs: 75, UnInstalls: 65 },
        { name: 'Jun', Installs: 80, UnInstalls: 40 },
        { name: 'Jul', Installs: 95, UnInstalls: 80 },
        { name: 'Aug', Installs: 70, UnInstalls: 25 },
        { name: 'Sep', Installs: 95, UnInstalls: 15 },
        { name: 'Oct', Installs: 85, UnInstalls: 85 },
        { name: 'Nov', Installs: 65, UnInstalls: 25 },
        { name: 'Dec', Installs: 65, UnInstalls: 75 }
    ];

    // const transformData = (data) => {
    //     return data.map((item) => ({
    //         date: formatDate(item.date), // Transform date format
    //         users: item.active_users
    //     }));
    // };

    // Function to format date from YYYYMMDD to YYYY-MM-DD
    // const formatDate = (dateString) => {
    //     const year = dateString.slice(0, 4);
    //     const month = dateString.slice(4, 6);
    //     const day = dateString.slice(6, 8);
    //     return `${year}-${month}-${day}`;
    // };

    // async function fetchAnalytics(selectedDates) {
    //     try {
    //         const response = await getAnalyticsData(selectedDates[0], selectedDates[1]);
    //         if (response.status === 200) {
    //             const {
    //                 analyticsData,
    //                 viewsData,
    //                 usersByDeviceData,
    //                 usersByLocationData,
    //                 growthRateData,
    //                 activeUsersData,
    //                 topUserActivitiesData
    //             } = response?.data;

    //             const transformedData = transformData(activeUsersData);
    //             const ActiveUsersData = {
    //                 Yearly: transformedData,
    //                 monthly: transformedData,
    //                 weekly: transformedData
    //             };

    //             setTotalViews(viewsData);
    //             setTopActivities(topUserActivitiesData);
    //             setActiveUsers(ActiveUsersData);
    //             console.log('Analytics Data:', analyticsData);
    //             console.log('+++++++++++', topUserActivitiesData);
    //             console.log('Users by Device Data:', usersByDeviceData);
    //             console.log('Users by Location Data:', usersByLocationData);
    //             console.log('Growth Rate Data:', growthRateData);
    //         }
    //     } catch (error) {
    //         // Handle error
    //         console.error('Error fetching users:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    // useEffect(() => {
    //     fetchAnalytics(selectedDates);
    //     setLoading(false);

    // }, [selectedDates]);

    const data = registercount?.map((item, index) => {
        return {
            name: item.month.slice(0, 3),
            Registration: item.count
        };
    });
    const labels = dailydata?.map((item) => item.day);

    const datasets = [
        {
            label: 'New Users/Day',
            data: dailydata?.map((item) => item.userCount),
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)'
        },
        {
            label: 'Games/Day',
            data: dailydata?.map((item) => item.gameCount),
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 1)'
        }
    ];

    const getCardProps = (primary) => {
        const isPrimaryGreaterThan500 = Number(primary) > 500;

        return {
            color: isPrimaryGreaterThan500 ? 'green' : 'red',
            iconPrimary: isPrimaryGreaterThan500 ? FileUploadTwoToneIcon : FileDownloadTwoToneIcon
        };
    };
    useEffect(() => {
        const getallcounts = async () => {
            try {
                const response = await AxiosInstance.get('/admin/get-all-counts');
                // console.log('response', response);
                if (response.status === 200) {
                    setAllCounts(response.data);
                }
            } catch (err) {
                console.error(err);
            }
        };

        getallcounts();
    }, []);

    useEffect(() => {
        const getregisterationcount = async () => {
            try {
                const response = await AxiosInstance.get('/admin/get-monthlyUser-count');
                console.log('response', response);
                if (response.status === 200) {
                    setregistercount(response.data.data);
                }
            } catch (err) {
                console.error(err);
            }
        };

        getregisterationcount();
    }, []);

    useEffect(() => {
        const getfullusersCount = async () => {
            try {
                const response = await AxiosInstance.get('/admin/get-fullyearuser-count');
                console.log('response', response);
                if (response.status === 200) {
                    setdailydata(response.data.dailyData);
                }
            } catch (err) {
                console.error(err);
            }
        };

        getfullusersCount();
    }, []);

    return (
        <Grid container spacing={2}>
            {allCounts && (
                <>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={allCounts.dailyActiveUserCount}
                            secondary="Daily Active users"
                            {...getCardProps(allCounts.dailyActiveUserCount)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={allCounts.monthlyActiveUserCount}
                            secondary="Monthly Active Users"
                            {...getCardProps(allCounts.monthlyActiveUserCount)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={allCounts.dailyGameCount}
                            secondary="Daily Games played"
                            color={'#90CAF9'}
                            iconPrimary={SportsEsportsTwoToneIcon}
                        />
                    </Grid>

                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={allCounts.monthlyGameCount}
                            secondary="Monthly Games played"
                            color={'red'}
                            iconPrimary={SportsEsportsTwoToneIcon}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={allCounts.activeUsersCount}
                            secondary="Total Active Users"
                            color={'#90CAF9'}
                            iconPrimary={GroupAddTwoToneIcon}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={allCounts.inactiveUserCount}
                            secondary="Total Inactive Users"
                            color={'#00C853'}
                            iconPrimary={GroupTwoToneIcon}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={allCounts.deletedUserCount}
                            secondary="Total Deleted Users"
                            color={'red'}
                            iconPrimary={GroupRemoveTwoToneIcon}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={allCounts.totalUserCount}
                            secondary="Total Number of Users"
                            color={'orange'}
                            iconPrimary={PeopleTwoToneIcon}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={`${((allCounts.activeUsersCount / allCounts.totalUserCount) * 100).toFixed(2)}%`}
                            secondary="Active Users Percentage"
                            color={'orange'}
                            iconPrimary={PeopleTwoToneIcon}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={`${((allCounts.inactiveUserCount / allCounts.totalUserCount) * 100).toFixed(2)}%`}
                            secondary="Inactive Users Percentage"
                            color={'orange'}
                            iconPrimary={PeopleTwoToneIcon}
                        />
                    </Grid>
                </>
            )}

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={12}>
                        <LineChart labels={labels} datasets={datasets} />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary={totalViews}
                            secondary="Total Views on App Store"
                            color={'orange'}
                            iconPrimary={AssessmentOutlinedIcon}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard primary="145" secondary="Total Downloads" color={'#90CAF9'} iconPrimary={FileDownloadIcon} />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard primary="2900" secondary="Total Installs" color={'#00C853'} iconPrimary={SystemUpdateIcon} />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard primary="500" secondary="Total Uninstalls" color={'red'} iconPrimary={ThumbDownAltOutlinedIcon} />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary="53%"
                            secondary="Percentage Change in Installs"
                            color={'orange'}
                            iconPrimary={GroupRemoveTwoToneIcon}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard
                            primary="13%"
                            secondary="Percentage Change in Uninstalls"
                            color={'red'}
                            iconPrimary={PeopleTwoToneIcon}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard primary="66%" secondary="Daily Active Users" color={'green'} iconPrimary={GroupRemoveTwoToneIcon} />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <ReportCard primary="33%" secondary="Daily Inactive Users" color={'red'} iconPrimary={PeopleTwoToneIcon} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CustomBarChart
                            isLoading={false}
                            title="Installs vs. Uninstalls"
                            data={installVsInstall}
                            dataKeys={Installation}
                            statusOptions={statusOptions}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomBarChart
                            isLoading={false}
                            title="New Registrations"
                            data={data}
                            dataKeys={userGrowth}
                            statusOptions={statusOptions}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
