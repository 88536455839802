import { Grid, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import MainCard from './main';

const PieChart = ({ ref, chartData, heading }) => {
    return (
        <MainCard>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="subtitle1">{heading}</Typography>
                </Grid>
                <Grid
                    item
                    sx={{
                        '& .apexcharts-legend-text': {
                            marginRight: '3px'
                        }
                    }}
                >
                    <Chart ref={ref} id="chart1" {...chartData} />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default PieChart;
